/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //
        $('.page-container').fitVids();

        if (!Modernizr.svg) {
            $('img[src*="svg"]').attr('src', function() {
                return $(this).attr('src').replace('.svg', '.png');
            });
        }

        var menu = null;

        enquire.register("screen and (max-width: 719px)", {
            deferSetup : true,

            setup: function() {
                // console.log("enquire setup");

                menu = new mlPushMenu( document.getElementById( 'mp-menu' ), document.getElementById( 'menu-toggle' ), {
                    type : 'cover',
                    backClass: 'page-nav__back',
                    closeClass: 'page-nav__close'
                });
            },
            unmatch: function() {
                // console.log("enquire unmatch");
                //window.mlPushMenu._resetMenu();
                menu._resetMenu();

                delete window.mlPushMenu;

            },
            destroy: function() {
                // console.log("enquire destroy");
            }
        }, true);


        if ($().owlCarousel !== undefined) {
          var gallery = $(".owl-gallery").owlCarousel({
            items: $(".owl-gallery").data('columns'),
            lazyLoad: true, //true
            loop: true,
            margin: 20,
            autoHeight: true,
            autoplay: false,
            autoplayTimeout: 10000,
            autoplayHoverPause: true,
            // URLhashListener: true,
            dots: true,
            // nav: true,
            // startPosition: 'URLHash'
            responsive: {
              0: {
                items: 1
              },
              480: {
                items: 2
              },
              960: {
                items: 3
              }
            }
          });
        }

        $.featherlightGallery.prototype.afterContent = function () {
          var caption = this.$currentTarget.data('caption');
          if (caption) {
            this.$instance.find('.caption').remove();
            $('<div class="caption u-pt- ">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
          }
        };

        $(".owl-gallery").featherlightGallery({
          galleryFadeOut: 300,
          previousIcon: '«',
          nextIcon: '»',
        });

        $.featherlight.prototype.afterContent = function () {
          var caption = this.$currentTarget.next('.wp-caption-text').text();
          if (caption) {
            this.$instance.find('.caption').remove();
            $('<div class="caption u-pt- ">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
          }
        };

        $('figure.wp-caption > a[href$=".jpg"], figure.wp-caption > a[href$=".jpeg"], figure.wp-caption > a[href$=".png"], article a[href$=".jpg"], article a[href$=".jpeg"], article  a[href$=".png"]')
          .filter(function () {
            return $(this)
              .parent()
              .is(":not(.gallery-item)");
          })
          .featherlight({});


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
